import React, { useState, useRef, useLayoutEffect } from 'react';

import 'react-phone-number-input/style.css';

import {ReactComponent as PhoneNextIcon} from '../../assets/phone-next-icon.svg';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import './style.scss';

export default function({onSubmit, ...props}) {
  const [value, setValue] = useState('+')

  const isValid = isValidPhoneNumber(value);
  const inputRef = useRef(PhoneInput);
  // const handleChange = (value) => {
  //   onChange(value);
  //   setValue(value);
  // }

  useLayoutEffect(() => {
    inputRef.current.scrollIntoView();
  }, [inputRef])

  return (
    <div className="phone-input-component">
      <PhoneInput
        {...props}
        ref={inputRef}
        value={value}
        onChange={setValue}
        autoFocus
      />
      <button onClick={() => onSubmit(value)} disabled={!isValid} className="next">
        <PhoneNextIcon />
      </button>
    </div>
  )
}
