import React from 'react';
import {isMobile} from 'react-device-detect';
import {ReactComponent as BiometricIntroStepLogo} from '../../assets/biometric-intro-step.svg';

import './style.scss';

export default function ({
  onSubmit = () => {}
}) {
  const size = isMobile ? '141px' : '256px';
  return <div className="biometric-intro-component">
    <BiometricIntroStepLogo width={size} height={size} />
    <h2 className="title">Снимите биометрические данные</h2>
    <p className="text-intro">Биометрия позволяет безопасно идентифицировать человека.<br/>Для заполнения биометрических данных потребуется только камера.</p>

    <button onClick={onSubmit} className="btn-primary">Приступить</button>
  </div>;
}
