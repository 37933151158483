import React, { useState } from 'react';

import { useHistory } from "react-router-dom";

import { useAuth } from '../../utils/authContext';

import './style.css';

const TermsRow = ({ terms, onSelect }) => {
  return <div className="row">
    <div className="row-title">
      Я ознакомлен и согласен с условиями
      {`\n`}
      <a
        className="terms-link"
        href="https://ovision.ru/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        {terms}
      </a>
    </div>
    <div>
      <input onClick={onSelect} className="terms-checkbox" type="checkbox" />
    </div>
  </div>
}

export default function({
  onSubmit = () => {}
}) {
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const auth = useAuth();
  const history = useHistory();

  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);

  return <div className="terms-card-component">
    <h3 className="terms-card-title">Необходимо согласиться с условиями</h3>
    <div className="terms-card">
      <TermsRow onSelect={() => setTerms(!terms)} terms="Пользовательского соглашения" />
      <br />
      <TermsRow onSelect={() => setPrivacy(!privacy)} terms={`Политики персональных данных`} />
      <button disabled={!privacy || !terms} className="btn-primary accept-button" onClick={() => history.push(auth.isAuthenticated ? `/biometric?code=${code}` : `/signIn?code=${code}`)}>Продолжить</button>
    </div>
  </div>;
};
