import React from 'react';

import './style.scss';

import {ReactComponent as Icon} from '../../assets/success-registration-step.svg';
import {ReactComponent as DeclineIcon} from '../../assets/decline-icon.svg';

export default function({
  company = '',
  address = '',
  time = '',
  sender = {},
  type = '',
  status = 'new', 
  onAccept = () => {},
  onDecline = () => {}
}) {
  // const status = 'decline';
  return <div className="invite-component">
    <h2 className="title">{company}</h2>
    {address && <span className="company-address">{address}</span>}
    {time && <span className="invite-time">{time}</span>}

    {sender && Object.keys(sender).length > 0 && <div className="sender">
      от
      {sender.avatar && <img className="avatar" alt="" src={sender.avatar} />}
      <span className="name">{sender.name}</span>
    </div>}

    {status === 'new' && <>
      <button className="btn-primary accept" onClick={onAccept}>{type !== 'invite_corporate' ? 'Принять' : 'Отправить заявку'}</button>
      {type !== 'invite_corporate' && <button className="btn-primary decline" onClick={onDecline}>Отклонить</button>}
    </>}

    {status === 'accepted' && <>
      <div className="status-container">
        <Icon />
        <span className="accepted-text">{type !== 'invite_corporate' ? 'Приглашение принято!' : 'Заявка отправлена!'}</span>
      </div>
    </>}
    {status === 'decline' && <>
      <div className="status-container">
        <DeclineIcon />
        <span className="decline-text">Приглашение отклонено!</span>
      </div>
    </>}
    
  </div>;
};
