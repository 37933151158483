import React from 'react';
import { useHistory, Redirect } from "react-router-dom";
import styled from 'styled-components';

import { useAuth } from '../../utils/authContext';

import pageWrap from '../../components/pageWrap/pageWrap';

import Indicator from '../../components/progress/progress';

import BiometricInfoStep from '../../components/biometricSteps/biometricIntro';

import './style.scss';

const Wrapper = styled.div`
  // max-width: 463px;
  width: 100%;
  margin: 0 auto;
  // padding: 0 20px;
`;

export function Biometric(props) {
  return <div className="biometricContainer">
    <Wrapper>
      <Indicator steps={5} step={3} />
      <BiometricInfoStep {...props} />
    </Wrapper>
  </div>;
}

export default pageWrap(function(props) {
  const auth = useAuth();

  if (auth.isLoading) {
    return null;
  }

  if (auth.user.hasFace) {
    if (!auth.user.name) {
      return <Redirect to={`/biometric/name?code=${props.invite.code}`} />;
    }
    return <Redirect to={`/invite?code=${props.invite.code}`} />;
  }

  const history = useHistory();
  return <Biometric {...props} onSubmit={() => history.push(`/biometric/scan?code=${props.invite.code}`)} />
});
