import React from 'react';

import './style.css';

export default function({
  invite = {},
  onClickAccept = () => {},
}) {
  if (Object.keys(invite).length === 0) {
    return <div className="invite-information-component">
      <h3 className="invite-information-title">Приглашение не найдено.</h3>
    </div>
  }

  const {
    company = '',
    address = '',
    time = '',
  } = invite;

  return <div className="invite-information-component">
    <h3 className="invite-information-title">Вы получили приглашение в</h3>
    <div className="info-text-container">
      <h3 className="company-name">{company}</h3>
      {address && <span className="company-address">{address}</span>}
      {time && <span className="invite-time">{time}</span>}
      <button onClick={onClickAccept} className="btn-primary accept-button">Принять приглашение</button>
    </div>
  </div>;
};
