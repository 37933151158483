import React, { useState } from 'react';
import {isMobile} from 'react-device-detect';
import {ReactComponent as SmsStepLogo} from '../../assets/sms-step.svg';

import SmsCodeInput from '../smsCodeInput/smsCodeInput';

import './style.scss';

export default function({ onSubmit, hasError = false }) {
  const [submitting, setSubmitting] = useState(false);

  const handleChange = async value => {
    if (value.length === 4) {
      console.log('send request');
      setSubmitting(true);

      try {
        await onSubmit(value);  
      } catch (error) {
        console.log(error);
      }
      
      setSubmitting(false);
    }
  };

  const size = isMobile ? '141px' : '219px';

  return <div className="sms-step-component">
    <SmsStepLogo width={size} height={size} />
    <h2 className="title">Введите код из смс</h2>
    <SmsCodeInput loading={submitting} onChange={handleChange} />
    {hasError && <div className="error-message">Неверный код из смс</div>}
  </div>;
};
