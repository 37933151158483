import React from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';

import {isMobile} from 'react-device-detect';

import Indicator from '../../components/progress/progress';

import Scan from '../../components/scan/scan';

import ApiClient from '../../client/api';

import './style.scss';

const Wrapper = styled.div`
  max-width: 511px;
  width: 100%;
  // margin: 0 auto;
  // padding: 0 20px;
  height: 100%;
  position: relative;
`;

export function ScanPage (props) {
  // const { step = 3 } = props;

  const progressStyle = {
    background: isMobile ? '#272D3D' : 'transparent',
    paddingTop: isMobile ? '27px' : '54px',
    paddingBottom: isMobile ? '11px' : '48px',
  };

  return <div className="scanContainer">
    <Wrapper>
      <Indicator style={progressStyle} steps={5} step={3} />
      <Scan {...props} />
    </Wrapper>
  </div>;
}

export default function(props) {
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const history = useHistory();
  const handleSubmit = face => {
    ApiClient.uploadFace(face)
      .then(() => {
        history.push(`/biometric/name?code=${code}`);
      }).catch(() => {
        alert('uploadFace error');
      });
  }

  return <ScanPage onSubmit={handleSubmit} {...props} />
};
