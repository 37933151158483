import React, { useEffect, useState, useContext, createContext } from 'react';

import {
  Route,
  Redirect,
  // useHistory,
} from 'react-router-dom';

import ApiClient from '../client/api';

const authContext = createContext();

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const token = localStorage.getItem('token');
  // const history = useHistory();
  const [user, setUser] = useState({});
  const [isAuthenticated, setAuthenticated] = useState(!!token && token !== null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      getUserProfile();
    }
    setLoading(!!token);
  }, [isAuthenticated, token])

  const getUserProfile = () => {
    return ApiClient.getUser()
      .then((res) => {
        console.log(res.data.data);
        setUser(res.data.data);
      }).catch((error) => {
        setAuthenticated(false);
        localStorage.clear();
      }).finally(() => {
        setLoading(false);
      });
  };

  const getSms = (phone, cb) => {
    return ApiClient.getSmsCode({ phone })
      .then((response) => {
        console.log('getSmsCode response', response);
        cb(response.data.hash);
      })
      .catch((error) => {
        console.log('getSmsCode error', error);
        throw error;
      });
  };

  const signin = (hash, smsCode, cb) => {
    return ApiClient.sendSmsCode({ hash, sms: smsCode })
      .then((response) => {
        console.log('sendSmsCode response', response);
        if (response.data) {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          setAuthenticated(true);
          cb();
        }
      })
      .catch((error) => {
        console.log('sendSmsCode error', error);
        throw error;
      });
  };

  const signout = cb => {

  };

  return {
    isAuthenticated,
    isLoading,
    user,
    getUserProfile,
    getSms,
    signin,
    signout
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function PrivateRoute({ children, invite = {}, ...rest }) {
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');

  let auth = useAuth();

  console.log('auth.isAuthenticated', auth.isAuthenticated);

  if (!code) {
    return <Redirect to="/"/>
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/?code=${code}`,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export function InviteRoute({ children, ...rest }) {
  let auth = useAuth();
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (auth.user && auth.user !== null && Object.keys(auth.user).length > 0) {
      setInit(true);
    }
  }, [auth.user])

  if (!init) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user.hasFace && auth.user.name ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: auth.user.name ? '/biometric' : '/biometric/name',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
