import axios from 'axios';

const FormData = require('form-data');

const apiDomain = process.env.REACT_APP_API_DOMAIN;

const DEFAULT_CONTENT_TYPE = 'application/json';

const DEFAULT_HEADERS = {
  'Content-Type': DEFAULT_CONTENT_TYPE,
};

export const AUTH_CONFIG = {
  grant_type: 'ovision',  
  client_id: '2',
  client_secret: 'Ooq9uSUPbWvpBm0ML5RyPzSlnnhVtTL4s8TYwRAZ',
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

class APIClient {
  constructor() {
    this.client = axios.create({
      timeout: 60000,
      headers: DEFAULT_HEADERS,
      baseURL: apiDomain,
    });
  }

  async getSmsCode(values) {
    const data = {...AUTH_CONFIG, ...values};
    const response = await this.client.post(`/api/oauth/login?lang=ru`, data);
    return response;
  }
  
  async sendSmsCode(values) {
    const data = {...AUTH_CONFIG, ...values};
    const response = await this.client.post(`/api/oauth/token?lang=ru`, data);
    return response;
  }

  async getUser() {
    const token = localStorage.getItem('token');

    const response = await this.client.get(`/api/v1/profile/?lang=ru`, { headers: { Authorization: `Bearer ${token}` } });
    return response;
  }

  async uploadFace(face) {
    const token = localStorage.getItem('token');
    const form = new FormData();
    form.append('face', dataURLtoFile(face, 'face.jpg'));
    return this.client.post('/api/v1/profile/face', form, { headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` } });
  }

  async updateProfile(data) {
    const token = localStorage.getItem('token');
    return this.client.put('/api/v1/profile', data, { headers: { Authorization: `Bearer ${token}` } });
  }

  async getInvite(code) {
    const token = localStorage.getItem('token');
    return this.client.get(`/api/v1/invitations/code/${code}`, { headers: { Authorization: `Bearer ${token}` } });
  }

  async getActivity(id) {
    const token = localStorage.getItem('token');
    return this.client.get(`/api/v1/activity/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  }

  async accept(id) {
    const token = localStorage.getItem('token');
    return this.client.post(`/api/v1/invitations/${id}/accept`, {}, { headers: { Authorization: `Bearer ${token}` } });
  }

  async decline(id) {
    const token = localStorage.getItem('token');
    return this.client.put(`/api/v1/invitations/${id}/decline`, {}, { headers: { Authorization: `Bearer ${token}` } });
  }

  async detect(face) {
    // const token = localStorage.getItem('token');
    const form = new FormData();
    form.append('files', dataURLtoFile(face, 'face'));
    return this.client.post('https://ml-models-test.germanywestcentral.cloudapp.azure.com/api/detector_test', form, { headers: {'Content-Type': 'multipart/form-data' } });
  }
};

const client = new APIClient();
export default client;
