import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import MainPage from './pages/main/MainPage';
import SignInPage from './pages/signIn/SignInPage';
import BiometricInfoStep from './pages/biometric/biometric';
import Scan from './pages/scan/scan';
import NamePage from './pages/name/name';
import InvitePage from './pages/invite/invite';

import { ProvideAuth, PrivateRoute, InviteRoute } from './utils/authContext';

import './App.css';

const apiDomain = process.env.REACT_APP_API_DOMAIN;

function InviteSwitch({ invite }) {
  let { path } = useRouteMatch();
  return <Switch>
    <InviteRoute exact path={path}>
      <InvitePage invite={invite} />
    </InviteRoute>
  </Switch>
}

function BiometricSwitch({ invite }) {
  let { path } = useRouteMatch();
  return <Switch>
    <Route exact path={path}>
      <BiometricInfoStep invite={invite} />
    </Route>
    <Route path={`${path}/scan`}>
      <Scan invite={invite} />
    </Route>
    <Route path={`${path}/name`}>
      <NamePage invite={invite} />
    </Route>
  </Switch>
}

function App() {
  const url = new URL(window.location.href);
  const [code] = useState(url.searchParams.get('code'));
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [invite, setInvite] = useState();

  useEffect(() => {
    setLoading(true);
    if (code && code !== null) {
      fetch(`${apiDomain}/api/v1/invitations/code/${code}`).then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        if (response.data && response.data !== null) {
          console.log('response.data', response);
          setInvite(response.data);
        }
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [code]);

  if (isLoading) {
    return null;
  }

  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <PrivateRoute path="/biometric">
            <BiometricSwitch invite={invite}  />
          </PrivateRoute>
          <PrivateRoute path="/invite">
            <InviteSwitch invite={invite} />
          </PrivateRoute>
          <Route path="/signIn">
            <SignInPage invite={invite} />
          </Route>
          <Route path="/">
            <MainPage invite={invite} onSubmit={() => history.push(`/signIn?code=${code}`)} />
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

export default App;
