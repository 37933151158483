import React from 'react';

import {ReactComponent as Icon} from '../../assets/success-registration-step.svg';

import './style.scss';

export default function() {
  return <div className="success-registration-component">
    <Icon />
    <h4>Вы успешно зарегистрированы в системе!</h4>
  </div>
}