import React from 'react';
import './style.scss';

const FULL_SIZE = 160;

export default function({
  steps = 3,
  step = 1,
  style = {},
}) {
  const current = FULL_SIZE / steps * step;

  return <div className="progress-component" style={style}>
    <div className="indicator">
      <div className="progress" style={{ width: current + 'px' }} />
    </div>
    <span className="info">{`Шаг ${step}/${steps}`}</span>
  </div>
}
