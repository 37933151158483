import React from 'react';
// import { Redirect } from 'react-router-dom';

export default function(Component) {
  return props => {
    // const { invite } = props;
  
    // if (!invite) {
    //   return Redirect('/');
    // }
  
    return <Component {...props} />;
  }
} 
