import React, { useState, useLayoutEffect, useRef } from 'react';

import './style.scss';

export default function({
  onChange = () => {},
  loading = false,
}) {
  const inputRef = useRef();
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    let { value } = event.target;

    if (/^\d+$/.test(value) || value === '') {
      setValue(value);
      onChange(value);
    }
  }

  useLayoutEffect(() => {
    inputRef.current.focus();
    inputRef.current.scrollIntoView();
  });

  return (
    <div className="sms-code-input-component">
      {loading && <div className="indicator">
        loading...
      </div>}
      <input
        ref={inputRef}
        value={value}
        maxLength={4}
        onChange={handleChange}
        autoFocus
      />
      
      {/* <div className="dotted">
        <div className="dot">{value[0]}</div>
        <div className="dot">{value[1]}</div>
        <div className="dot">{value[2]}</div>
        <div className="dot">{value[3]}</div>
      </div> */}
    </div>
  )
}
