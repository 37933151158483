import React from 'react';
import Webcam from "react-webcam";

import {isMobile} from 'react-device-detect';

import Mask from './mask';

import APIClient from '../../client/api';

import './style.scss';

export default class Scan extends React.Component {
  constructor(props) {
    super(props);

    this.webcamRef = React.createRef(Webcam);
    this.detectorRef = React.createRef();
    
    this.state = {
      detected: false,
      size: 0,
    };
  }

  getScreenshot = () => {
    const { onSubmit } = this.props;
    const imageSrc = this.webcamRef.current.getScreenshot();
    console.log('imageSrc', imageSrc);
    onSubmit(imageSrc);
  }

  init = async () => {
    this.interval = setInterval(() => {
      this.capture();
    }, 1500);
  }

  setSize = () => {
    this.setState({ size: this.detectorRef.current.offsetWidth / 2 - 1.5 });
    
  }

  componentDidMount() {
    this.init();
    console.log(this.detectorRef.current.offsetWidth);
    window.addEventListener('resize', this.setSize);
    this.setSize();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.setSize);
  }

  capture = async () => {
    if (!!this.webcamRef.current) {
      const pic = this.webcamRef.current.getScreenshot();
      APIClient.detect(pic).then(res => {
        if (res.data.face && res.data.face.length > 0) {
          this.setState({ detected: true });
        } else {
          this.setState({ detected: false });
        }
      }).catch(err => {
        this.setState({ detected: false });
      })
    }
  };

  render() {
    const { hasError } = this.props;
    const { detected, size } = this.state;

    let styleDetector = {};

    if (isMobile) {
      styleDetector = {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        bottom: 0,
        right: 0,
        left: 0,
        borderRadius: '0px',
      };
    }

    return <div className="scan-component" style={styleDetector}>
      <Webcam mirrored screenshotFormat="image/jpeg" ref={this.webcamRef} />
      <div className="mask-container">
        <Mask size={size} />
      </div>
      <div className="detector-container" >
        <div
          ref={this.detectorRef}
          className="detector"
          style={{
            height: 'auto',
            width: '80%',
            paddingTop: '80%',
            backgroundColor: hasError ? '#F54565' : 'transparrent',
            borderColor: hasError ? '#F54565' : '#7165FF',
          }}
        />
      </div>
      <div className="action-container">
        {hasError && <span className="error info">Постарайтесь не вертеть головой</span>}
        {!hasError && !detected && <span className="info" style={{ height: isMobile ? '80px' : '19px' }}>Разместите лицо по центру круга</span>}
        {detected && <button onClick={this.getScreenshot} className={`${isMobile ? 'btn-primary' : 'detected'} info`}>Начать сканирование</button>}
      </div>
    </div>
  }
}

// export default function({
//   hasError = false,
//   onSubmit = () => {},
// }) {
//   const webcamRef = React.useRef(null);
//   const [detected, setDetected] = useState(true);
//   // const video = document.getElementById('webcam');

//   useLayoutEffect(() => {
//     if (webcamRef.current.addEventListener) {
//       webcamRef.current.addEventListener('play',function()
//   {
//     console.log('test');
//   },false);
//     }
//   }, [webcamRef.current])

  

//   let size = 224;
//   if (hasError) {
//     size = 15;
//   }

//   const capture = React.useCallback(
//     () => {
//       const imageSrc = webcamRef.current.getScreenshot();
//       console.log('imageSrc', imageSrc);
//       onSubmit(imageSrc);
//     },
//     [webcamRef]
//   );

//   return <div className="scan-component">
//     <Webcam ref={webcamRef} />
//     <div className="mask-container">
//       <Mask size={size} />
//     </div>
//     <div className="detector-container">
//       <div
//         className="detector"
//         style={{
//           height: (size - 1.5) * 2 + 'px',
//           width: (size - 1.5) * 2 + 'px',
//           backgroundColor: hasError ? '#F54565' : 'transparrent',
//           borderColor: hasError ? '#F54565' : '#7165FF',
//         }}
//       />
//     </div>
//     <div className="action-container">
//       {hasError && <span className="error info">Постарайтесь не вертеть головой</span>}
//       {!hasError && !detected && <span className="info">Разместите лицо по центру круга</span>}
//       {detected && <button onClick={capture} className="detected info">Начать сканирование</button>}
//     </div>
//   </div>;
// }