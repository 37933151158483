import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// import {isIOS, isAndroid, isMobile} from 'react-device-detect';
import {ReactComponent as MainLogo} from '../../assets/main-logo.svg';

import InvitationCard from '../../components/invitationCard/invitationCard';
import TermsCard from '../../components/termsCard/termsCard';
import AppStoreIcons from '../../components/appStore/appStore';

import { useAuth } from '../../utils/authContext';

import './style.scss';

function MainPage({
  invite,
  onSubmit = () => {},
}) {
  const [showTerms, setShowTerms] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <div className="main-page">
      <div className="info-container">
        <div className="center">
          <MainLogo />
          {/* {isMobile && <AppStore />} */}
          {!showTerms && <InvitationCard invite={invite} onClickAccept={() => setShowTerms(true)} />}
          {showTerms && <TermsCard onSubmit={onSubmit} />}
        </div>
        {/* {!isMobile && <AppStore />} */}
        <AppStoreIcons />
      </div>
      {isTabletOrMobile ? null : <div className="device-container">
        <img className="device" alt="" src="device.png" />
      </div>}
    </div>
  );
}

export default function(props) {
  const auth = useAuth();

  if (auth.isLoading) {
    return null;
  }

  return <MainPage {...props} />
};
