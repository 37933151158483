import React from 'react';

export default function({size, ...props}) {
  return <svg width="100%" height="100%" {...props}>
    <mask id="mask">
      <rect fill="white" width="100%" height="100%" opacity="0.5" />
      <circle
        cx="50%"
        cy="50%"
        r={size}
      />
    </mask>
    <rect mask="url(#mask)" width="100%" height="100%" />
  </svg>
};
