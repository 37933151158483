import React from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import ApiClient from '../../client/api';

import pageWrap from '../../components/pageWrap/pageWrap';

import Indicator from '../../components/progress/progress';

import EditName from '../../components/editName/editName';

import { useAuth } from '../../utils/authContext';

import './style.scss';

const Wrapper = styled.div`
  // max-width: 463px;
  width: 100%;
  margin: 0 auto;
  // padding: 0 20px;
`;

export function Name(props) {
  return <div className="nameContainer">
    <Wrapper>
      <Indicator steps={5} step={4} />
      <EditName {...props} />
    </Wrapper>
  </div>;
}

export default pageWrap(function(props) {
  const history = useHistory();
  const auth = useAuth();

  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');

  const handleSubmit = data => {
    ApiClient.updateProfile(data)
      .then(async (res) => {
        await auth.getUserProfile();
        history.push(`/invite?showSuccessInfo=1&code=${code}`);
        console.log('updateProfile res', res);
      }).catch((error) => {
        console.log('updateProfile error', error);
        alert('updateProfile error', error);
      });
  }

  return <Name {...props} onSubmit={handleSubmit} />
});
