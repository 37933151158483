import React from 'react';

import {isIOS, isAndroid, isMobile} from 'react-device-detect';

import './style.css';

export default function() {
  return <div className="center">
    {!isMobile && <h3 className="info-text store-text">Скачайте приложение в AppStore или Google Play и воспользуйтесь полным функционалом нашей системы.</h3>}
    <div className="store-container">
      {isIOS || !isMobile ? <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/ru/app/o-vision/id1492056715"><img src="iconAppStore.png" alt="" className={`store-icon${!isMobile ? '' : ''}`} /></a> : null}
      {isAndroid || !isMobile ? <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.ovisionapp"><img src="iconGooglePlay.png" alt="" className="store-icon" /></a> : null}
    </div>
  </div>
};
