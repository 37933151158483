import React, { useState } from 'react';

import './style.scss';

export default function({
  onSubmit = () => {},
}) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const handleChange = field => (event) => {
    const { value } = event.target;

    if (field === 'firstName') {
      setFirstName(value);
    }

    if (field === 'lastName') {
      setLastName(value);
    }
  }

  const handleClick = () => onSubmit({
    firstName,
    lastName,
  });

  return <div className="edit-name-component">
    <h2 className="title">{`Осталось совсем\nнемного!`}</h2>

    <input value={firstName} onChange={handleChange('firstName')} placeholder="Имя" className="name-field" autoFocus />
    <input value={lastName} onChange={handleChange('lastName')} placeholder="Фамилия" className="name-field" />

    {firstName && lastName && <button onClick={handleClick} className="btn-primary">Готово</button>}
  </div>
};
