import React from 'react';
import {isMobile} from 'react-device-detect';
import {ReactComponent as PhoneStepLogo} from '../../assets/phone-step.svg';

import PhoneInput from '../phoneInput/phoneInput';

import './style.scss';

export default function({onSubmit}) {
  const size = isMobile ? '141px' : '219px';
  return <div className="phone-step-component">
    <PhoneStepLogo width={size} height={size} />
    <h2 className="title">Укажите Ваш номер телефона</h2>
    <PhoneInput onSubmit={onSubmit} />
  </div>;
}
