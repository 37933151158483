import React, { useState } from 'react';
import styled from 'styled-components';

import pageWrap from '../../components/pageWrap/pageWrap';

import PhoneStep from '../../components/phoneStep/phoneStep';
import SmsStep from '../../components/smsStep/smsStep';
import Indicator from '../../components/progress/progress';

import { Redirect, useHistory } from 'react-router-dom';

import { useAuth } from '../../utils/authContext';

import './style.scss';

const Wrapper = styled.div`
  max-width: 463px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

export function SignInPage({
  step = 1,
  getSmsCode = () => {},
  submit = () => {},
  hasError = false,
}) {
  return <div className="signInContainer">
     <Wrapper>
      <Indicator steps={5} step={step} />
      {step === 1 && <PhoneStep onSubmit={getSmsCode} />}
      {step === 2 && <SmsStep hasError={hasError} onSubmit={submit} />}
    </Wrapper>
  </div>;
};

export default pageWrap(function(props) {
  const auth = useAuth();
  const history = useHistory();
  const token = localStorage.getItem('token');

  const {invite = {}} = props;

  if (token) {
    return <Redirect to={`/?code=${invite.code}`} />;
  }

  const [ hash, setHash ] = useState();
  const [ hasError, setHasError ] = useState(false);

  const getSmsCode = async phone => {
    try {
      await auth.getSms(phone, (hash) => {
        setHash(hash);
      });
    } catch (error) {
      console.log('getSmsCode error', error);
    }
  }

  const submit = async smsCode => {
    setHasError(false);
    try {
      await auth.signin(hash, smsCode, () => {
        console.log('test');
        history.push(`/biometric?code=${invite.code}`);
      });
    } catch (error) {
      console.log('submit error', error);
      setHasError(true);
    }
  }

  return <SignInPage
    getSmsCode={getSmsCode}
    submit={submit}
    step={hash ? 2 : 1}
    hasError={hasError}
    {...props}
  />
});