import React, { useState, useEffect, useLayoutEffect } from 'react';

import { useAuth } from '../../utils/authContext';

import ApiClient from '../../client/api';

import pageWrap from '../../components/pageWrap/pageWrap';

import InviteComponent from '../../components/invite/invite';
import SuccessRegistrationComponent from '../../components/successRegistration/successRegistration';
import { ReactComponent as Logo } from '../../assets/main-logo.svg';

import './style.scss';

export function InvitePage({
  showInfo = false,
  ...props
}) {
  return <div className="inviteContainer">
    <div className="logo">
      <Logo />
    </div>
    {showInfo && <SuccessRegistrationComponent />}
    <InviteComponent {...props} />
  </div>;
}

export default pageWrap(function(props) {
  const auth = useAuth();

  const url = new URL(window.location.href);
  const showSuccessInfo = url.searchParams.get('showSuccessInfo');
  const code = url.searchParams.get('code');

  // const history = useHistory();
  const [activityId, setActivityId] = useState();
  const [activity, setActivity] = useState({});
  const [status, setStatus] = useState(activity.status || 'new');

  const [isLoading, setLoading] = useState(true);

  useLayoutEffect(() => {
    ApiClient.getInvite(code)
    .then((response) => {
      if (response.data.data)
      setActivityId(response.data.data.activityId);
    })
    .catch(() => {
      alert('getInvite error');
    });
  }, [code]);

  useEffect(() => {
    if (activityId) {
      ApiClient.getActivity(activityId)
        .then((response) => {
          setActivity(response.data.data);
        })
        .catch(() => {
          alert('getActivity error');
        }).finally(() => {
          setLoading(false)
        });
    }
  }, [activityId]);
  
  if (auth.isLoading || isLoading) {
    return null;
  }

  const accept = () => {
    const { inviteId } = activity;
    console.log('accept inviteId', inviteId);
    ApiClient.accept(inviteId)
    .then(() => {
      setStatus('accepted');
    })
    .catch(() => {

    });
  };

  const decline = () => {
    const { inviteId } = activity;
    console.log('decline inviteId', inviteId);
    ApiClient.decline(inviteId)
    .then(() => {
      setStatus('decline');
    })
    .catch(() => {

    });
  };

  // if (auth.user.hasFace) {
  //   if (!auth.user.name) {
  //     return <Redirect to="/biometric/name" />;
  //   }
  //   return <Redirect to="/invite" />;
  // }

  // const history = useHistory();

  console.log(props);

  return <InvitePage
    showInfo={showSuccessInfo === '1' && status === 'new'}
    {...props.invite}
    {...activity}
    onAccept={accept}
    onDecline={decline}
    status={status}
  />;
});
